|<template>
  <b-modal :active.sync="value" :can-cancel="false" id="modal-waitinglist">
    <button @click="close()" class="btn-close-wait-list" type="button">
      <i class="icon-mks icon_alert_cl"></i>
    </button>
    <div class="body-modal" v-if="dateString != null">
      <div class="center-icon">
        <p class="span-text-waitinglist" v-if="!isAndresDc()">{{ $t('waitingList') }}</p>
        <p class="span-text-waitinglist" v-if="isAndresDc()">Anuncio de visita</p>
      </div>
      <div class="Content-modal-text text-waiting-list" v-if="!isAndresDc()">
        {{$t('waitingListTextDate') + dateString.substr(0, 10) + $t('waitingListTextHour') + dateString.substr(11) }}
        {{ $t('waitingListText1') + $t('waitingListText2') }}
        <strong>{{ $t('waitingListText3') }}</strong> {{ $t('waitingListText4') }}
      </div>
      <div class="Content-modal-text text-waiting-list" v-if="isAndresDc()">
        {{$t('waitingListTextDate') + dateString.substr(0, 10) }} no tenemos Reservas disponibles, pero nuestras puertas siempre estarán abiertas.
        Deja tus datos en un Anuncio de visita y nuestros acomodadores harán todo lo posible por encontrarte un rinconcito en esta Casa.
        Recuerda que un Anuncio de visita no es una Reserva. la ubicación estará sujeta a la disponibilidad en el momento de tu llegada.
      </div>
      <div class="Content-modal-text text-waiting-list" v-if="activateFormulary">
        {{$t('waitingListText5')}}</div>
      <div
        class="bottom-modal-waitinglist"
        style="margin-top: 20px; margin-right: 5px"
        v-if="!activateFormulary"
      >
        <div class="container-button">
          <button @click.prevent="setWaitingList(2)" class="button_login">
            {{ $t('modalWaitingList.continue') }}
          </button>
        </div>
      </div>
      <div id="form" v-else>
        <div class="columns">
          <div class="column is-6">
            <b-field
              :label="$t('name')+':'"
              :message="errors.first('name')"
              :type="{'is-danger': errors.has('name')}"
            >
              <b-input
                expanded
                name="name"
                type="text"
                v-model="name"
                v-validate="'alpha_spaces|required|min:2'"
              ></b-input>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field
              :label="$t('email')+':'"
              :message="errors.first('mail')"
              :type="{'is-danger': errors.has('mail')}"
            >
              <b-input
                @keydown.native.space.prevent
                expanded
                name="mail"
                type="text"
                v-model="email"
                v-validate="'required|email'"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="main_phone">
              <label class="text_phone">{{ $t('modalWaitingList.textPhone') }}</label>
              <div class="field field_phone">
                <div class="control has-icons-left control_cel">
                  <div class="select">
                    <select
                      @change="changedPaisSelectedReservation(countryCode)"
                      class="select_country"
                      name="pais"
                      v-model="countryCode"
                      v-validate="'required'"
                    >
                      <option :key="key" :value="pais" v-for="(pais, key) in paises">{{pais.label}}</option>
                    </select>
                  </div>
                  <div class="icon is-small is-left">
                    <i class="icons_data icon_number_register"></i>
                  </div>
                </div>
                <label class="label_indicative">+{{countryCode !== null ? countryCode.value : ""}}</label>
                <input
                  autocomplete="phone-new"
                  class="input"
                  name="phone"
                  placeholder="celular"
                  type="phone"
                  v-model="phone"
                  v-validate="validateTel"
                />
              </div>
              <span class="help is-danger" v-if="errors.has('phone')">{{errors.first('phone')}}</span>
            </div>
          </div>
          <div class="column is-6 container-button">
            <button
              :class="{ 'disable-button' : disable }"
              :disabled="disable"
              @click.prevent="save"
              class="button_login"
            >
              {{ $t('modalWaitingList.send') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import authTypes from '@/store/types/auth';
import BanderaPais from 'country-json/src/country-by-abbreviation.json';
import serviceWaitingList from '@/helpers/apis/waitinList.js';
export default {
  props: ['value'],
  data () {
    return {
      dateString: null,
      activateFormulary: false,
      name: null,
      email: null,
      phone: null,
      countryCode: null,
      validateTel: '',
      paises: [],
      bandera: null,
      disable: false,
      loading: false
    };
  },
  mounted () {
    this.dateString = this.$moment(this.date).format('YYYY-MM-DD hh:mm a');
    this.listPaisesReservation();
  },
  methods: {
    validateAttributes () {
      if (this.errors.items.length <= 0 && (this.name !== null && this.name !== '') && (this.email !== null && this.email !== '') && (this.countryCode != null) && (Object.keys(this.countryCode).length > 0) && this.phone != null) {
        return true;
      }
      return false;
    },
    save () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.disable = true;
          const data = {
            vendorId: this.vendorId,
            people: this.people,
            referrer: 'whitelabel',
            name: this.name,
            phone: this.phone,
            indicativo: this.countryCode.value,
            email: this.email,
            date: this.date,
            notificationType: 'email'
          };
          this.loading = true;
          serviceWaitingList.post('add/waitTime', data).then(({ data }) => {
            this.loading = false;
            if (data.code === 200) {
              this.$store.commit(whiteLabelTypes.mutations.setPopupWaitingList, false);
              this.$store.commit(whiteLabelTypes.mutations.setPopupSuccessWaitingList, true);
            }
          }).catch(() => {
            this.loading = false;
          });
        }
      });
    },
    changedPaisSelectedReservation (indicativo) {
      if (indicativo !== undefined) {
        let aux2 = null;
        const ind = indicativo.value;
        _.forEach(BanderaPais, function (item) {
          const data2 = item.country + ' ( +' + ind + ' )';
          if (data2 === indicativo.label) {
            aux2 = item.abbreviation.toLowerCase();
          }
        });
        this.bandera = aux2 !== null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
        indicativo.selected = true;
        this.countryCode = indicativo;
        this.validateTel = this.validateTelephone(this.countryCode.value);
        document.documentElement.style.setProperty('--userBandera', 'url(' + this.bandera + ')');
      }
    },
    listPaisesReservation () {
      this.paises = this.listCountry();
      this.paises.unshift({
        value: 57,
        label: 'Colombia ( +57 )',
        selected: true
      });
      if (Object.keys(this.user).length > 0) {
        this.name = this.user.displayName;
        this.email = this.user.email;
        this.phone = this.user.phone.trim();
        this.bandera = this.user.bandera;
        this.countryCode = this.paises.find(p => p.value === parseInt(this.user.indicativo));
        this.validateTel = this.validateTelephone(this.countryCode.value);
        document.documentElement.style.setProperty('--userBandera', 'url(' + this.bandera + ')');
      } else {
        if (this.restaurant.params.indicativeRegion != null) {
          let aux2 = null;
          this.countryCode = this.paises.find(p => p.value === parseInt(this.restaurant.params.indicativeRegion));
          this.validateTel = this.validateTelephone(this.countryCode.value);
          _.forEach(BanderaPais, (item) => {
            const data2 = item.country + ' ( +' + this.restaurant.params.indicativeRegion + ' )';
            if (data2 === this.countryCode.label) {
              aux2 = item.abbreviation.toLowerCase();
            }
          });
          this.bandera = aux2 !== null ? 'https://api.precompro.com/banderas/' + aux2 + '.png' : null;
          document.documentElement.style.setProperty('--userBandera', 'url(' + this.bandera + ')');
        }
      }
    },
    close () {
      this.$store.commit(whiteLabelTypes.mutations.setPopupWaitingList, false);
      this.$store.commit(reservationTypes.mutations.setSelectedHour, null);
    },
    setWaitingList (option) {
      if (option === 2) {
        this.activateFormulary = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      date: reservationTypes.getters.selectedHourAction,
      people: reservationTypes.getters.selectedPeople,
      vendorId: whiteLabelTypes.getters.vendorId,
      user: authTypes.getters.user,
      restaurant: whiteLabelTypes.getters.vendor
    })
  },
  destroyed () {
    document.documentElement.style.setProperty('--userBandera', null);
  }
};
</script>
<style lang="scss">
#modal-waitinglist {
  .body-modal {
    background: #fff;
    padding: 40px;
    color: #000;
    border-radius: 10px;
  }
  .container-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    align-items: center;
  }
  .icon_login_account {
    -webkit-mask-image: url(../../assets/images/icons/icon_login_account.svg);
    mask-image: url(../../assets/images/icons/icon_login_account.svg);
    width: 12px;
    height: 8px;
    background: #787885;
  }
  .iconsLoginModal {
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    display: flex;
  }
  .button_login {
    width: 162px;
    height: 30px;
    background: #444B57;
    border-radius: 26px;
    border: none;
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    label {
      cursor: pointer;
      margin-right: 20px;
    }
  }
  .second_button_login {
    width: 32px;
    height: 20px;
    background-color: #fff;
    border-radius: 26px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -20px;
    cursor: pointer;
  }
  .animation-content.modal-content {
    max-width: 575px !important;
  }
  .control_cel {
    width: 50px;
  }
  .main_phone {
    width: 100%;
  }
  .text_phone {
    font-weight: 600;
    font-size: 1rem;
    text-align: left;
    display: flex;
    color: #363636;
  }
  .field_phone {
    display: flex;
    align-items: center;
    font-weight: bold;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    margin-bottom: 10px !important;
    width: 100%;
    input {
      border: none;
    }
    label {
      font-size: 0.9rem;
      font-weight: normal;
    }
    select{
      border: none;
    }
  }
  .control_cel {
    .select:not(.is-multiple):not(.is-loading)::after {
      right: 0;
      left: 30px;
    }
  }
  .control.has-icons-left .input,
  .control.has-icons-left .taginput .taginput-container.is-focusable,
  .taginput .control.has-icons-left .taginput-container.is-focusable,
  .control.has-icons-left .select select {
    padding-left: 2em;
  }
  .label_indicative {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.48);
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 0.9rem;
    margin-left: -18px;
  }
  .icon_number_register {
    background-image: var(--userBandera);
    width: 15px;
    height: 15px;
    border-radius: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .control_cel {
    .select:not(.is-multiple):not(.is-loading)::after {
      right: 0;
      left: 30px;
    }
  }
  .icons_data {
    mask-repeat: no-repeat !important;
    mask-position: center !important;
    display: flex;
  }
  .span-text-waitinglist {
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-family: 'Source Sans Pro'!important;
    font-weight: bold;
    text-align: center;
  }

  .option-waitinglist {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 200px;
  }
  .text-option-waitinglist {
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  }
  .bottom-modal-waitinglist {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .center-icon {
    display: flex;
    justify-content: center;
    padding: 0px 0px 22px 0px;
  }
  .header-icon {
    background-image: url(../../assets/images/icons/header-modal-waitinglist.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    height: 45px;
    width: 45px;
  }
  .icon-branch-waitinglist {
    cursor: pointer;
    background-image: url(../../assets/images/icons/icon-branch-waitinglist.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-self: center;
    height: 61px;
    width: 60px;
  }
  .icon-waitinglist {
    cursor: pointer;
    background-image: url(../../assets/images/icons/icon-waitinglist.svg);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-self: center;
    height: 60px;
    width: 45px;
  }
  .text-waiting-list {
    text-align: center;
    align-content: center;
    font-size: 14px;
    font-weight: 400;
  }
}
.disable-button {
  pointer-events: none;
}
.btn-close-wait-list {
  position: absolute;
  right: 10px;
  top: 18px;
  border: 0;
  background: transparent;
  cursor: pointer;
  .icon_alert_cl{
    background-color: #444B57;
    height: 12px;
    width: 12px;
  }
}
@media (max-width: 768px) {
  #modal-waitinglist{
    .text-waiting-list {
      text-align: justify !important;
      align-content: center;
      font-size: 14px;
      font-weight: 400;
    }
    .container-button{
      justify-content: center !important;
    }
  }
}
</style>
