<template class="Big-Mapa">
  <b-modal :active.sync="hola" :can-cancel="false" class="ModalGaira">
    <div style="background:#fff; padding:20px; ">
      <div class="title">
        <p class="covers">{{ $t('availableArea') }}</p>
        <div class="text-right-close-btn">
          <i @click="CloseGairaModal" class="fa fa-remove"></i>
        </div>
      </div>
      <div class="bgGaira">
        <div class="filas">
          <div class="logo col1">
            <img alt class src="@/assets/images/gairaMap/logo.png" />
          </div>
          <div class="col2">
            <div
              :class="{'selected':(general == 'general')}"
              @click="selectZone(227, 3426)"
              class="caja caja-0 align-lateral"
            >
              <img alt class="images-text left" src="@/assets/images/gairaMap/estudio.png" />
              <img alt class="images-text right" src="@/assets/images/gairaMap/3er.png" />
            </div>
            <div
              :class="{'selected':(general == 'general')}"
              @click="selectZone(227, 3426)"
              class="caja caja-1 align-lateral"
            >
              <img alt class="images-text" src="@/assets/images/gairaMap/provincia2.png" />
              <img alt class="images-text" src="@/assets/images/gairaMap/sd-piso.png" />
            </div>
            <div
              :class="{'selected':(general == 'provincia')}"
              @click="selectZone(227, 3423)"
              class="caja caja-2 col-10 align-lateral-2"
            >
              <img alt class="images-text" src="@/assets/images/gairaMap/provincia1.png" />
              <img alt class="images-text" src="@/assets/images/gairaMap/2do.png" />
            </div>
          </div>
          <div class="col3">
            <img alt class="carto-img" src="@/assets/images/gairaMap/brujula.png" />
          </div>
        </div>

        <div class="filas">
          <div class="tarima col1">
            <img alt src="@/assets/images/gairaMap/tarima.png" />
          </div>
          <div class="col2 centro-auditorios">
            <div
              :class="{'selected':(general == 'auditorio')}"
              @click="selectZone(227,2079)"
              class="caja caja-3"
            >
              <img alt class src="@/assets/images/gairaMap/auditorio1.png" />
            </div>
            <div
              :class="{'selected':(general == 'general')}"
              @click="selectZone(227,3426)"
              class="caja caja-4"
            >
              <img alt class src="@/assets/images/gairaMap/auditorio2.png" />
            </div>
          </div>
          <div class="col3">
            <img alt class="segundo" src="@/assets/images/gairaMap/sdo-piso-horizontal.png" />
            <div class="no-margin fila-doble">
              <div
                :class="{'selected':(general == 'distrito')}"
                @click="selectZone(227,3425)"
                class="caja caja-5"
              >
                <img alt class src="@/assets/images/gairaMap/distrito1.png" />
              </div>
              <div
                :class="{'selected':(general == 'general')}"
                @click="selectZone(227,3426)"
                class="caja caja-6"
              >
                <img alt class src="@/assets/images/gairaMap/distrito2.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="filas">
          <div class="col1"></div>
          <div :class="{'selected':(general == 'juglares')}" class="col2 caja caja-2">
            <div @click="selectZone(227, 999)" class="col juglar">
              <img alt class src="@/assets/images/gairaMap/juglares.png" />
            </div>
            <img alt class src="@/assets/images/gairaMap/2do.png" />
          </div>
          <div class="col3"></div>
        </div>
      </div>
      <div class="Legends">
        <div class="FullWidth" v-if="zoneOption.length > 0">
          <div class="Legends">
            <img align="left" src="@/assets/images/gairaMap/color1.png" width="30" />
            <p
              class="covers"
            >&nbsp; {{ $t('cover') }} {{50000|currency('$',0)}} y consumo {{150000|currency('$',0)}}</p>
            <p class="covers yello">/ {{ $t('locationCocktail') }}</p>
          </div>
          <div class="Legends">
            <img align="left" src="@/assets/images/gairaMap/color2.png" width="30" />
            <p
              class="covers"
            >&nbsp; cover {{40000|currency('$',0)}} y consumo {{160000|currency('$',0)}}</p>
            <p class="covers yello">/ {{ $t('locationTable') }}</p>
          </div>
          <div class="Legends">
            <img align="left" src="@/assets/images/gairaMap/color3.png" width="30" />
            <p class="covers">&nbsp; cover {{35000|currency('$',0)}}</p>
            <p class="covers yello">/ {{ $t('locationMuch') }}</p>
          </div>
        </div>
        <button :disabled="next" @click="confirm" class="bnt-next-mapa">
          {{ $t('continueUpper') }}
          <i>></i>
        </button>
      </div>
      <div class="color-text" v-html="copy"></div>
    </div>
  </b-modal>
</template>
<script>
// ================ Libraries =============
import { mapActions } from 'vuex';
// ================ Types  ================
import whiteLabelTypes from '@/store/types/whiteLabel';
import reservationTypes from '@/store/types/reservation';
import '@/assets/styles/gairaMapStyle.css';

export default {
  props: ['prevDate'],
  data () {
    return {
      zoneOption: [],
      zone: false,
      copy: null,
      hola: true,
      general: '',
      next: true
    };
  },
  watch: {
    zones () {
      this.zones.forEach(item => {
        if (!item.selectSubZone) {
          this.zoneOption.push({
            id: item.id,
            displayName: item.displayName,
            copy: item.copy
          });
        } else {
          item.subSections.forEach(i => {
            this.zoneOption.push({
              id: item.id + '=>' + i.id,
              displayName: i.title,
              copy: item.copy,
              cover: i.cover,
              consumption: i.consumption
            });
          });
        }
      });
    }
  },
  mounted () {
    this.getZones({ vendorId: this.vendorId });
    if (this.selectedTable != null) {
      this.selectZone(this.selectedZone, this.selectedTable);
    }
  },
  methods: {
    ...mapActions({
      getZones: reservationTypes.actions.getZones
    }),
    selectZone (zoneId, cases) {
      this.copy = this.zone.copy;
      this.$store.commit(reservationTypes.mutations.setSelectedZone, zoneId);
      this.$store.commit(reservationTypes.mutations.setSelectedTable, cases);
      this.$store.commit(reservationTypes.mutations.setSelectedDate, false);
      switch (cases) {
        case 2079:
          this.general = 'auditorio';
          break;
        case 3423:
          this.general = 'provincia';
          break;
        case 3426:
          this.general = 'general';
          break;
        case 3425:
          this.general = 'distrito';
          break;
        case 999:
          this.general = 'juglares';
          break;
      }
      this.next = false;
      this.zoneId = zoneId;
    },
    confirm () {
      this.$store.commit(whiteLabelTypes.mutations.setIsAvalability, false);
      let dateInit = this.dateNowInitial();
      let response = this.setSelectedDates(
        this.prevDate,
        dateInit,
        this.allEvents,
        this.zoneId
      );
      let seft = this;
      this.$parent.$emit('isEventDay', response.evento);
      setTimeout(function () {
        seft.$emit('hide-select-zone-map', true);
      }, 500);
    },
    CloseGairaModal () {
      this.$emit('closeModalGairaMap', true);
      this.$emit('hide-select-zone-map', true);
    }
  }
};
</script>
<style lang="scss" scoped>

.tarima {
  img {
    width: 100%;
  }
}
.text-right-close-btn {
  position: absolute;
  margin-top: -26px;
  right: 0px;
  font-size: 25px;
}

.Legends {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  .FullWidth {
    width: 100%;
  }
}

.carto-img {
  max-height: 140px;
  margin-left: 25%;
  position: absolute;
  right: 9%;
  top: 9%;
  width: 16%;
}

.filas {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: auto;
  padding: 5px;
  .col1 {
    width: 20%;
    margin: 5px;
  }
  .col2 {
    width: 70%;
    margin: 5px;
    &.centro-auditorios {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      .caja {
        width: 48%;
      }
    }
  }
  .col3 {
    width: 10%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin: 5px;
    .fila-doble {
      display: flex;
      align-items: stretch;
      height: 70%;
    }
    .segundo {
      text-align: center;
      height: inherit;
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.images-text {
  margin: 5px;
}

.btn-next:hover {
  background-color: black;
  opacity: 0.5;
}

.caja-0:hover,
.caja-0.selected {
  border: 2px dashed #5c380d;
  background-color: rgba(175, 170, 133, 0.8);
}
.caja-1:hover,
.caja-1.selected {
  border: 2px dashed #5c380d;
  background-color: rgba(175, 170, 133, 0.8);
}

.caja {
  border: 2px dashed #5c380d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.caja img {
  padding: 1px 8px;
  max-height: 20px;
}

.caja-0 {
  border: 2px dashed #5c380d;
  padding: 5px 0px;
  background-color: rgba(232, 228, 191, 0.8);
  display: flex;
}

.caja-1 {
  border: 2px dashed #5c380d;
  padding: 5px 0px;
  background-color: rgba(232, 228, 191, 0.8);
  display: flex;
}

.caja-1 img {
  padding: 1px 8px;
}

.caja-2 {
  border: 2px dashed #5c380d;
  padding: 5px 0px;
  background-color: rgba(233, 195, 108, 0.8);
  display: flex;
}

.caja-2:hover,
.caja-2.selected {
  border: 2px dashed #5c380d;
  background-color: rgba(191, 138, 31, 0.5);
}

.caja-2 img {
  padding: 1px 5px;
}

.caja-3 {
  background-color: rgba(172, 39, 41, 0.9);
}

.caja-3:hover {
  border: 2px dashed #5c380d;
  background-color: rgba(172, 39, 41, 0.6);
}
.caja-3.selected {
  border: 2px dashed #5c380d;
  background-color: rgba(172, 39, 41, 1);
}

.caja-4 {
  background-color: rgba(232, 228, 191, 0.6);
}

.caja-4:hover,
.caja-4.selected {
  border: 2px dashed #5c380d;
  background-color: rgba(175, 170, 133, 0.8);
}

.caja-5 img {
  max-height: inherit !important;
  padding: 3px;
}

.caja-5 {
  background-color: rgba(233, 195, 108, 0.8);
  height: 100%;
  border: 2px dashed #5c380d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.caja-5:hover,
.caja-5.selected {
  border: 2px dashed #5c380d;
  background-color: rgba(191, 138, 31, 0.5);
}

.caja-6 {
  background-color: rgba(232, 228, 191, 0.6);
  border: 2px dashed #5c380d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.caja-6:hover,
.caja-6.selected {
  border: 2px dashed #5c380d;
  background-color: rgba(175, 170, 133, 0.8);
}

.caja-6 img {
  max-height: inherit !important;
  padding: 3px;
}

.no-margin {
  margin: 0;
}

.juglar {
  text-align: center;
  width: 80%;
}

.align-lateral {
  justify-content: space-between;
  margin-bottom: 10px;
  width: 80%;
}
.align-lateral-2 {
  justify-content: space-between;
  margin-bottom: -13px;
  width: 70%;
}

.covers {
  font-size: 25px;
  color: #c9c7b6;
}
.covers.yello {
  font-size: 20px;
  color: #efc47e;
  margin-left: 10px;
}

.covers img {
  height: 50%;
  padding: 0px;
}

.logo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.logo img {
  width: 70%;
  margin-top: 25% !important;
}
.selected {
  box-shadow: 1px 1px 8px 3px #333;
}

.bnt-next-mapa:disabled {
  color: #c9c7b6;
}
.bnt-next-mapa {
  color: rgba(172, 39, 41, 1);
  font-size: 25px;
  background: none;
  border: none;
  display: flex;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .tarima img {
    height: 100px;
  }

  .filas {
    padding: 2px 2px;
    margin-top: -8px;
  }

  .row {
    flex-wrap: nowrap;
  }

  .images-text {
    width: 35%;
    height: 35%;
  }

  .caja-1 img {
    padding: 1px 4px;
    max-height: 10px;
  }

  .caja-2 img {
    padding: 1px 4px;
    max-height: 10px;
  }

  .caja {
    border: 2px dashed #5c380d;
    padding: 1px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bgGaira {
    background-repeat: round;
    padding: 25px 15px;
    min-height: 100px;
    margin-bottom: 20px;
  }

  .caja-5 {
    height: 100%;
    margin-top: 0px;
    margin-left: 0px;
    border: 0px dashed #5c380d;
    margin-right: 10px;
  }

  .caja-5 img {
    height: 50px;
  }

  .caja-6 {
    height: 100%;
    margin-top: 0px;
    margin-left: 0px;
    border: 0px dashed #5c380d;
  }

  .caja-6 img {
    height: 50px;
  }

  .caja img {
    padding: 1px 2px;
    max-height: 9px;
  }

  .caja-4 {
    width: calc(33.3% - 20px);
    margin-left: 10px;
  }

  .caja-2 {
    margin-top: 5px;
    margin-bottom: 1%;
  }

  .covers {
    font-size: 15px;
  }

  .caja-0 img.left {
    padding: 1px 8px;
    max-height: 30px;
  }
  .caja-0 img.right {
    padding: 0px 6px;
    max-height: 10px;
  }
  .Legends {
    flex-direction: column-reverse;
  }
}
</style>
